import React, { FC, useEffect, useRef, useState } from 'react';
import { UserfrontProvider } from '@userfront/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import style from './App.module.scss';
import { useDispatch, useSelector } from '../..';
import Preloader from '../Preloader/Preloader';
import Maintenance from '../Maintenance/Maintenance';
import { getMaintanence } from '../../services/creators/site-creator';
import HomePageRu from '../../pages/HomePage/ru/HomePageRu';
import { TranslationProvider } from '../../contexts/context';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Login from '../../pages/Auth/Login/Login';
import Signup from '../../pages/Auth/Signup/Signup';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import ProtRouteAuth from '../ProtRouteAuth/ProtRouteAuth';
import ProtectedRouteLogged from '../ProtectedRouteLogged/ProtectedRouteLogged';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { alldashTabs } from '../../utils/constants';
import AboutUs from '../../pages/AboutUs/AboutUs';
import BlogPageRu from '../../pages/MethodsPage/ru/BlogPageRu';
import SingleMethodPage from '../../pages/SingleMethodPage/SingleMethodPage';
import { checkTokenThunk } from '../../services/creators/auth-creator';
import Reset from '../../pages/Auth/Reset/Reset';

const App: FC = () => {
  const dispatch = useDispatch();

  const { maintenance, isLoading, isLogged } = useSelector((state) => ({
    maintenance: state.site.maintanence,
    isLoading: state.site.isLoading,
    isLogged: state.auth.isLogged,
  }));

  useEffect(() => {
    if (!isLogged) {
      dispatch(getMaintanence());
    }
  }, [isLogged]);

  useEffect(() => {
    if (!isLogged) {
      dispatch(checkTokenThunk());
    }
  }, [isLogged]);

  const maintenanceJsx = (
    <Routes>
      <Route element={<Maintenance />} path='/' />
      <Route element={<Login />} path='/signin' />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );

  const appJsx = (
    <>
      <Header />
      <Routes>
        <Route element={<HomePageRu />} path='/' />
        <Route element={<AboutUs />} path='/about' />
        <Route path='/methods' element={<BlogPageRu />} />
        <Route path='/methods/:slug' element={<SingleMethodPage />} />
        <Route element={<ProtRouteAuth />}>
          <Route element={<Signup />} path='/signup' />
          <Route element={<Login />} path='/signin' />
          <Route element={<Reset />} path='/reset' />
        </Route>

        <Route element={<ProtectedRouteLogged />}>
          {alldashTabs.map((item: any) => (
            <Route element={<Dashboard />} path={item.path} />
          ))}
        </Route>

        <Route element={<NotFoundPage />} path='*' />
      </Routes>
      <Footer />
    </>
  );

  const afterLoaded = (
    <div className={style.page}>
      {maintenance && !isLogged ? maintenanceJsx : appJsx}
    </div>
  );
  return (
    <TranslationProvider>
      <UserfrontProvider tenantId='5nx97dyb'>
        {isLoading ? <Preloader /> : afterLoaded}
      </UserfrontProvider>
    </TranslationProvider>
  );
};

export default App;
