import React, { FC, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from '../../..';
import MethodsPage from '../MethodsPage';
import { siteURL } from '../../../utils/constants';

const BlogPageRu: FC = () => {
  const dispatch = useDispatch();
  const { articles, articlesLoaded } = useSelector((state) => ({
    articles: state.site.articles,
    articlesLoaded: state.site.articlesLoaded,
  }));

  useEffect(() => {
    if (articles.length < 1 && !articlesLoaded) {
      // dispatch(getArticlesThunk()); // get Articles by Language lets load local articles
    }
  }, [articles, articlesLoaded]); // if languages changes then articles changed

  return (
    <HelmetProvider>
      <Helmet>
        <title>Как учить иностранные языки</title>
        <meta content='Блог. Как учить иностранные языыи' name='description' />
        <link href={`${siteURL}/en/methods`} hrefLang='en' rel="alternate" />
      </Helmet>
      <MethodsPage articles={articles} />
    </HelmetProvider>
  );
};

export default BlogPageRu;
