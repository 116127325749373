import React, { FC } from 'react';
import { Navigate, Outlet, Route, RouteProps } from 'react-router-dom';
import { useSelector } from '../..';
import Preloader from '../Preloader/Preloader';

const ProtectedRouteLogged: FC<RouteProps> = ({ children, ...rest }) => {
  const { path } = { ...rest };
  const { isLogged, isTokenPending } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    isTokenPending: state.auth.isTokenRequesting,
  }));
/*
  if (isTokenPending) {
    return (
      <Route {...rest}>
        <Preloader />
      </Route>
    );
  } */

  return isLogged ? <Outlet/> : <Navigate to='/'/>
};

export default ProtectedRouteLogged;
