import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../..';
import useFormAndValidation from '../../../hooks/useForm';
// import { addLangsToUserThunk, loginFunctionThunk, signupFunction } from '../../../services/action-creators/auth-creator';
import style from './Signup.module.scss';
import { loginThunk, signupThunk } from '../../../services/creators/auth-creator';

const Signup: FC = () => {
  const dispatch = useDispatch();

  const { isRegistered } = useSelector((state) => ({
    isRegistered: state.auth.isRegistered,
  }));

  const [chosenLang, setChosenLang] = useState<string>('deu');

  const setLang = (e: any) => {
    const { id } = e.target;
    setChosenLang(id);
  };

  const initial = {
    values: {
      email: '',
      password: '',
    },
    errors: {
      email: '',
      password: '',
    },
    validities: {
      email: true,
      password: true,
    },
  };

  const {
    values, handleChange, errors, validities, isValid,
  } = useFormAndValidation(initial);

  const submitRegister = (e: any) => {
    e.preventDefault();
    dispatch(signupThunk({ email: values.email, password: values.password }));
    /* dispatch(signupFunction({
      email: values.email,
      password: values.password,
      langs:
      {
        [chosenLang]: {
          beginner: { success: 1, repeat: 1 },
        },
      },
    }));
    */
  };

  useEffect(() => {
    if (isRegistered) {
      console.log('isreg?', isRegistered, ' ', values.email, ' ', values.password);
      dispatch(loginThunk({ email: values.email, password: values.password }));
    }
  }, [isRegistered]);

  // TODO: change radio buttons to correct models names,
  // since now on sign up there's langs of base1, base2

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <h2>Регистрация нового пользователя</h2>
        <form className={style.form} onSubmit={submitRegister}>
          <input
            className={style.input}
            name="email"
            onChange={handleChange}
            type="text"
            value={values.email}
          />
          <input
            className={style.input}
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
          />
          <div>
            <p>Выберите 1й изучаемый язык</p>
            <div className={style.langs}>
              <input
                className={style.radio} id="deu"
                name="langchoice" onChange={setLang}
                type="radio"
                value={chosenLang}
              />
              <label className={style.radio__label} htmlFor="deu">Немецкий</label>

              <input
                className={style.radio} id="esp"
                name="langchoice" onChange={setLang}
                type="radio"
                value={chosenLang}
              />
              <label className={style.radio__label} htmlFor="esp">Испанский</label>

              <input
                className={style.radio} id="fr"
                name="langchoice" onChange={setLang}
                type="radio"
                value={chosenLang}
              />
              <label className={style.radio__label} htmlFor="fr">Французский</label>
            </div>
            <p>Вы сможете добавить дальнейшие языки позже из вашего кабинета</p>
          </div>
          <input className={style.button} onClick={submitRegister} type="submit" value="Зарегистрироваться" />
        </form>
      </div>
    </div>
  );
};

export default Signup;
