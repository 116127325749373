import { combineReducers } from 'redux';
import authReducer from './authReducer';
import siteReducer from './siteReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
  /*
  learn: newBaseReducer,
  newbase: newBaseReducer,  
  base: baseReducer,
  admin: adminServiceReducer, */
});
export default rootReducer;
