import React, { FC } from 'react';
import style from './Preloader.module.scss';

const Preloader: FC = () => (
  <div className={style.container}>
    <div className={style.innerBox}>
      <div className={style.loader} />
    </div>
  </div>
);

export default Preloader;
