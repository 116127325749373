import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkTokenApi, loginApi, registerApi } from '../../utils/authApi';

export const checkTokenThunk = createAsyncThunk(
  'auth/checkToken',
  async (_, thunkAPI) => checkTokenApi()
);

export const signupThunk = createAsyncThunk(
  'auth/signup',
  async ({ email, password, langs }: any, thunkAPI) => {
    const transferData = { email, password, langs };
    return registerApi(transferData);
  }
);

export const loginThunk = createAsyncThunk(
  'auth/login',
  async ({ email, password }: any, thunkAPI) =>
    loginApi({ email, password })
);
