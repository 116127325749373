import React, { FC } from 'react';
import clsx from 'clsx';
import style from './FirstPreview.module.scss';
import Button from '../../../../../utils/ui-library/Buttons/Button/Button';
import useLocal from '../../../../../hooks/useLocal';

import icon from '../../../../../vendor/images/tick.svg';
import iconEasy from '../../../../../vendor/images/easy-first.svg';
import iconEasy2 from '../../../../../vendor/images/easy-icon.svg';
import tick2nd from '../../../../../vendor/images/tick-2nd.svg';
import iconInter from '../../../../../vendor/images/interest-icon.svg';

const Benefit: FC<{ item: { title: string; list: React.ReactNode, icon?: any } }> = ({ item }) => (
  <li className={style.benefitLi}>
    <div className={style.columnHeader}>
      <img alt="icon" className={style.icon} src={item.icon} />
      <p className={style.benefitTitle}>{item.title}</p>
    </div>
    <ul className={style.benefitsList}>{item.list}</ul>
  </li>
);

const FirstPreview: FC = () => {
  const local = useLocal();
  const handleClick = () => {
    console.log('click');
  };
  const listFirst = (
    <>
      <li>простые предложения подойдут даже для начинающих</li>
      <li>разумное усложнение по мере прохождения</li>
      <li>понятный интерфейс</li>
    </>
  );

  const listSecond = (
    <li>мы не берем деньги за обучение</li>
  );

  const listThird = (
    <>
      <li>вы быстро можете научиться реально разговаривать</li>
      <li>тексты не повторяются</li>
      <li>разные задания</li>
    </>
  );

  const listFourth = (
    <>
      <li>Живые разговорные предложения</li>
      <li>Популярные и нестандартные фразы</li>
    </>
  );


  const benefistsAll = [{
    title: 'Легко',
    list: listFirst,
    icon: iconEasy,
  }, {
    title: 'Бесплатно',
    list: listSecond,
    icon: iconEasy2,
  },
  {
    title: 'Эффективно',
    list: listThird,
    icon: tick2nd,
  },
  {
    title: 'Интересно',
    list: listFourth,
    icon: iconInter,
  }];

  return (
    <div className={style.firstPreview}>
      <div className={style.innerBox}>
        <div className={style.twoBlocks}>
          <div className={clsx(style.column, style.textColumn)}>
            <h1 className={style.title}>{local({ page: 'home', key: 'thirdTitle' })}</h1>
          </div>
          <div className={clsx(style.column, style.subtitleText)}>
            <p className={style.subtitle}>
              {local({ page: 'home', key: 'thirdSubtitle' })}
            </p>
          </div>
        </div>
        <ul className={style.columns}>
          {benefistsAll.map((benefit) => (
            <Benefit key={benefit.title} item={benefit} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FirstPreview;
