import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from '../../..';
import useFormAndValidation from '../../../hooks/useForm';
import style from './Login.module.scss';
import { Link } from 'react-router-dom';

const Login: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, user } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    user: state.auth.user,
  }));

  const initial = {
    values: {
      email: '',
      password: '',
    },
    errors: {
      email: '',
      password: '',
    },
    validities: {
      email: true,
      password: true,
    },
  };

  const {
    values, handleChange, errors, validities, isValid,
  } = useFormAndValidation(initial);

  const submitRegister = (e: any) => {
    e.preventDefault();
    // dispatch(loginFunctionThunk({ email: values.email, password: values.password }));
  };

  /*

  useEffect(() => {
    console.log('is log?', isLogged);
    if (isLogged) {
      dispatch(addLangsToUserThunk({ lang: 'esp' }));
    }
  }, [isLogged]);
  */

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <h2>Вход (пользователь уже существует)</h2>
        <form className={style.form} onSubmit={submitRegister}>
          <input
            className={style.input}
            name="email"
            onChange={handleChange}
            type="text"
            value={values.email}
          />
          <input
            className={style.input}
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
          />
          <Link to='/reset'>Восстановить пароль</Link>
          <input className={style.button} onClick={submitRegister} type="submit" value="Войти" disabled={!isValid} />
        </form>
      </div>
    </div>
  );
};

export default Login;
