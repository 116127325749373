import React, { FC, useEffect } from 'react';
import style from './Maintenance.module.scss';
import { useDispatch, useSelector } from '../..';
import { Link } from 'react-router-dom';

const Maintenance: FC = () => {
  const { isLogged } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
  }));

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <div className={style.preview}>
          <h2 className={style.preview__title}>
            Сайт доступен для зарегистрированных пользователей.
          </h2>
          <Link to='/signin'>Страница входа</Link>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
