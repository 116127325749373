import { createSlice } from '@reduxjs/toolkit';
import { getMaintanence } from '../creators/site-creator';
import { tempArticles } from '../../utils/constants';
type TFirstInitial = {
  maintanence: boolean;
  isLoading: boolean;
  articles: Array<any>;
  articlesLoaded: boolean;
  currentLocal: string;
  mobMenuOpened: boolean;
  dashCourseTab: string;
  currentArticle: any;
};

const initialState: TFirstInitial = {
  maintanence: true,
  isLoading: false,
  articles: tempArticles,
  articlesLoaded: true,
  currentLocal: 'ru',
  mobMenuOpened: false,
  dashCourseTab: 'lessons',
  currentArticle: {
    titleRu: 'test article',
    mainTextRu: 'lorem ipsum',
    image: '',
  },
};

interface TFirstAction {
  readonly type: string;
  readonly payload: {
    payload: boolean;
  };
}
interface TchangeTab {
  readonly type: string;
  readonly payload: string;
}

export type TsiteActions = TFirstAction | TchangeTab;

const siteReducer = createSlice({
  name: 'site',
  initialState,
  reducers: {
    /*
    makeAppLoaded(state, action: TFirstAction) {
      state.isLoaded = action.payload.payload;
    },
    */
    checkMain(state, action: TFirstAction) {
      state.maintanence = true;
    },
    changeCourseTab(state, action: TchangeTab) {
      state.dashCourseTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaintanence.pending, (state, action: any) => {
      state.isLoading = true;
    });
    builder.addCase(getMaintanence.fulfilled, (state, action: any) => {
      state.isLoading = false;
      const maintfinal = action.payload[0].maint === "1";
      state.maintanence = maintfinal;
    });
    builder.addCase(getMaintanence.rejected, (state, action: any) => {
      state.isLoading = false;
      // some error making
    });
  },
});

export const { checkMain, changeCourseTab } = siteReducer.actions;

export default siteReducer.reducer;
