import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import style from './DashTabs.module.scss';
import { changeCourseTab } from '../../../services/reducers/siteReducer';
import { useDispatch, useSelector } from '../../..';
import { NavLink } from 'react-router-dom';
import { alldashTabs } from '../../../utils/constants';
import NavButton from '../../../utils/ui-library/Buttons/NavButton/NavButton';

import homeIcon from '../../../vendor/images/icons/home.png';
import secIcon from '../../../vendor/images/icons/information.png';
import thirIcon from '../../../vendor/images/icons/interphone.png';


const DashTabs: FC = () => {
    const dispatch = useDispatch(); 
    const switchTab = (e: any) => {
        const tabName = e.target.name;
        dispatch(changeCourseTab(tabName));
      };
      const { dashCourseTab } = useSelector((state) => ({
        dashCourseTab: state.site.dashCourseTab,
      }));
      const [images, setImages] = useState<any>([]);
      useEffect(() => {
        const newFile = [homeIcon, secIcon,thirIcon];
        setImages(newFile);

      },[]);

  return (
    <ul className={style.tabslist}>
      {alldashTabs.map((item: any, index: any) =>  <li key={item.title} className={style.itemlist}><NavButton path={item.path} name='dashtab' className={style.navbut}>
        <img src={images[index]} alt="dash item" className={style.icon} />
        <p className={style.par} >{item.title}</p></NavButton></li>)}
    </ul>   
  );

}

export default DashTabs;