import React, { FC } from 'react';
import style from './AboutUs.module.scss';
import useLocal from '../../hooks/useLocal';
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';

const AboutUs: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const local = useLocal();
  //    {local({ page: 'home', key: 'blogTitle' })}

  return (
    <PageWithSidebar titletext='О нас'>
      <p className={style.mainText}>some text about us</p>
    </PageWithSidebar>

  );
};

export default AboutUs;
