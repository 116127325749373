import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import './index.scss';
import App from './components/App/App';
import rootReducer from './services/reducers';
import {
  TypedUseSelectorHook,
  useSelector as selectorHook,
  useDispatch as dispatchHook,
  Provider,
} from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { TAppActions } from './utils/types';


// TODO: вынести store в отдельный файл для разделения логики
const store = configureStore({
  reducer: rootReducer,
});

type RootState = ReturnType<typeof store.getState>;

const useSelector: TypedUseSelectorHook<RootState> = selectorHook;

type AppDispatch = ThunkDispatch<RootState, never, TAppActions>;
const useDispatch = () => dispatchHook<AppDispatch>();

export { useSelector, useDispatch };

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
);

reportWebVitals();
