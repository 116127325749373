import React, { FC, useState } from 'react';
import clsx from 'clsx';
import style from './ShowingTab.module.scss';
import { useSelector } from '../../..';
import Course from '../Course/Course';
import { allCoursesTemp } from '../../../utils/constants';

const ShowingTab: FC = () => {
  const { dashCourseTab } = useSelector((state) => ({
    dashCourseTab: state.site.dashCourseTab,
  }));

  return (
    <div className={style.lessonsBox}>
      {dashCourseTab === 'lessons' ? (
        <div className={style.listTasks}>
          <h2 className={style.subtitle}>Пройденные уроки:</h2>
          {/*<DashListLessons dashTopicsList={topicsListForDash} />*/}
        </div>
      ) : (
        ''
      )}
      {dashCourseTab === 'courses' ? (
        <div className={style.courses}>
          <h2 className={style.subtitle}>Все курсы выбранного языка:</h2>
          <ul className={style.listCourses}>
            {allCoursesTemp.map((course: any) => (
              <Course key={course.courseName} course={course} />
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ShowingTab;
