import { createSlice } from '@reduxjs/toolkit';
import {
  checkTokenThunk,
  loginThunk,
  signupThunk,
} from '../creators/auth-creator';

type TUser = {
  email: string;
  _id?: number;
};

type TAuthInitial = {
  user: TUser;
  isRequesting: boolean;
  isLogged: boolean;
  isRegistered: boolean;
  isTokenRequesting: boolean;
};

const initialState: TAuthInitial = {
  user: {
    email: '',
  },
  isRequesting: false,
  isLogged: false,
  isRegistered: false,
  isTokenRequesting: false,
};

interface ILogin {
  readonly type: string;
  readonly payload: {
    user: TUser;
  };
}

export type TauthActions = ILogin;

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tempAutologin(state, action: ILogin) {
      state.isLogged = true;
      state.user = { email: action.payload.user.email };
    },
  },
  extraReducers: (builder) => {
    /*
    builder.addCase(signupFunction.pending, (state) => {
      state.isRequesting = true;
    });
*/
    builder.addCase(checkTokenThunk.fulfilled, (state, action: any) => {
      console.log('token success result: ', action.payload);
      state.isLogged = action.payload[0].token;
      //state.isLogged = true;
    });

    builder.addCase(signupThunk.fulfilled, (state, action: any) => {
      console.log('payload singup: ', action.payload);
      state.isRequesting = false; // registered, not logged in yet
      state.isRegistered = true;
    });

    builder.addCase(loginThunk.fulfilled, (state, action: any) => {
      console.log('login pay succes hm: ', action.payload[0]);
      state.user = action.payload[0].userLogin;
      state.isLogged = true;
    });
  },
});

export const { tempAutologin } = authReducer.actions;

export default authReducer.reducer;
