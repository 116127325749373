import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../..';
import style from './Menu.module.scss';
import useLocal from '../../hooks/useLocal';
import NavButton from '../../utils/ui-library/Buttons/NavButton/NavButton';

const MenuItemMobile: FC<{
  name: string;
  handleClick: any;
  path: string;
  text: any;
}> = ({ name, handleClick, path, text }) => {
  const { isLogged, correctLocal, mobMenuOpened } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    correctLocal: state.site.currentLocal,
    mobMenuOpened: state.site.mobMenuOpened,
  }));
  const local = useLocal();
  const dispatch = useDispatch();
  return (
    <li
      className={clsx(
        style.nav__item,
        isLogged ? style.nav__itemlogged : style.nav__itemnotlogged,
        mobMenuOpened ? style.item_mobile : ''
      )}
    >
      <NavButton name={name} onClick={handleClick} path={path}>
        {text}
      </NavButton>
    </li>
  );
};

const MenuItem: FC<{ name: string; path: string; text: any }> = ({
  name,
  path,
  text,
}) => {
  const { isLogged, correctLocal, mobMenuOpened } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    correctLocal: state.site.currentLocal,
    mobMenuOpened: state.site.mobMenuOpened,
  }));
  const local = useLocal();
  const dispatch = useDispatch();
  return (
    <li
      className={clsx(
        style.nav__item,
        isLogged ? style.nav__itemlogged : style.nav__itemnotlogged,
        mobMenuOpened ? style.item_mobile : ''
      )}
    >
      <NavButton name={name} path={path}>
        {text}
      </NavButton>
    </li>
  );
};

const Menu: FC<{ classN?: string; isMobile?: boolean }> = ({
  classN,
  isMobile,
}) => {
  const { isLogged, correctLocal, mobMenuOpened } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    correctLocal: state.site.currentLocal,
    mobMenuOpened: state.site.mobMenuOpened,
  }));
  const local = useLocal();
  const dispatch = useDispatch();

  const currentItemName = mobMenuOpened
    ? 'header_menu_item_mobile'
    : 'header_menu_item';

  const toggleMobileMenu = (e: any) => {
    // dispatch(toggleMobMenu({ opened: !mobMenuOpened }));
  };

  return isMobile ? (
    <nav className={clsx(style.nav, style.nav_mobile)}>
      <ul
        className={clsx(
          style.nav__list,
          classN || '',
          mobMenuOpened ? style.nav_mobile : ''
        )}
      >
        <MenuItemMobile
          handleClick={toggleMobileMenu}
          name={currentItemName}
          path={`/${correctLocal}`}
          text={local({ page: 'header', key: 'homepage' })}
        />

        <MenuItemMobile
          handleClick={toggleMobileMenu}
          name={currentItemName}
          path={`/${correctLocal}/methods`}
          text={local({ page: 'header', key: 'blog' })}
        />

        <MenuItemMobile
          handleClick={toggleMobileMenu}
          name={currentItemName}
          path={`/${correctLocal}/about`}
          text={local({ page: 'header', key: 'about' })}
        />

        {isLogged ? (
          <MenuItemMobile
            handleClick={toggleMobileMenu}
            name='header_menu_item_highl'
            path={`/${correctLocal}/dashboard`}
            text={local({ page: 'header', key: 'dashboard' })}
          />
        ) : (
          ''
        )}
        {/*isLogged && isAdmin ? (
          <MenuItemMobile
            handleClick={toggleMobileMenu}
            name='header_menu_item_admin'
            path={`/${correctLocal}/admin-access`}
            text={local({ page: 'header', key: 'adminCMS' })}
          />
        ) : (
          ''
        )*/}
      </ul>
    </nav>
  ) : (
    <nav className={style.nav}>
      <ul
        className={clsx(
          style.nav__list,
          classN || '',
          mobMenuOpened ? style.nav_mobile : ''
        )}
      >
        <MenuItem
          name={currentItemName}
          path='/'
          text={local({ page: 'header', key: 'homepage' })}
        />
        <MenuItem
          name={currentItemName}
          path='/methods'
          text={local({ page: 'header', key: 'blog' })}
        />

        <MenuItem
          name={currentItemName}
          path='/about'
          text={local({ page: 'header', key: 'about' })}
        />

        {isLogged ? (
          <MenuItem
            name='header_menu_item_highl'
            path='/dashboard'
            text={local({ page: 'header', key: 'dashboard' })}
          />
        ) : (
          ''
        )}
        {/*isLogged && isAdmin ? (
          <MenuItem
            name='header_menu_item_admin'
            path='/admin-access'
            text={local({ page: 'header', key: 'adminCMS' })}
          />
        ) : (
          ''
        )*/}
      </ul>
    </nav>
  );
};

export default Menu;
