import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch } from '../..';
import Button from '../../utils/ui-library/Buttons/Button/Button';
import style from './Method.module.scss';

const Method: FC<{ method: any; fromAdmin?: boolean; classColor?: any; }> = ({
  method,
  fromAdmin,
  classColor,
}) => {
  const dispatch = useDispatch();

  const openModalWithMethod = () => {
    // dispatch(showFormPopup({ show: true, popupItem: method }));
  };

  const link = `/methods${method.urlslug}`;

  /* was with gradient
   <div className={style.gradientBox}>
          <img alt={method.title} className={style.image} src={method.image} />
        </div>
        */

  return (
    <div className={clsx(style.method, classColor)}>
      <Link className={style.methodLink} to={link}>
        <img alt={method.title} className={style.image} src={method.image ? method.image : ''} />
        <div className={clsx(style.textBlock, classColor)}>
          <p className={style.title}>{method.titleRu}</p>
          <p className={style.exerpt}>{method.mainTextRu}</p>


          {fromAdmin ? (
            <div className={style.links}>
              <Link className={style.link} to={link}>
                Перейти на страницу
              </Link>
              <Button className={style.link} onClick={openModalWithMethod}>
                Редактировать
              </Button>
            </div>
          ) : (
            <p className={style.linkText}>
              Читать Далее &#10132;
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default Method;
