import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import style from './Additional.module.scss';
import NavButton from '../../../../../utils/ui-library/Buttons/NavButton/NavButton';
import useLocal from '../../../../../hooks/useLocal';
import { useDispatch, useSelector } from '../../../../..';
import Method from '../../../../../components/Method/Method';

const Additional: FC = () => {
  const local = useLocal();
  const dispatch = useDispatch();

  const { articles, articlesLoaded } = useSelector((state) => ({
    articles: state.site.articles,
    articlesLoaded: state.site.articlesLoaded,
  }));

  useEffect(() => {
    if (articles.length < 1 && !articlesLoaded) {
     //  dispatch(getArticlesThunk()); // get Articles by Language
    }
  }, [articles, articlesLoaded]); // if languages changes then articles changed

  return (
    <div className={style.additional}>
      <div className={style.innerBox}>
        <h2 className={style.title}>
          {' '}
          {local({ page: 'home', key: 'blogTitle' })}
        </h2>
        <ul className={style.columns}>
          {articles.map((item: any, index: any) => (
            <Method key={index} method={item} />
          ))}
        </ul>
      </div>

    </div>
  );
};

export default Additional;
