import React, { FC, useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../..';

import style from './Header.module.scss';
import Button from '../../utils/ui-library/Buttons/Button/Button';
import Menu from '../Menu/Menu';
import LangsSwitcher from '../Langs/LangsSwitcher';
import useLocal from '../../hooks/useLocal';
import NavButton from '../../utils/ui-library/Buttons/NavButton/NavButton';
import logoImg from '../../vendor/images/logo-black.png';
import mobileBurgerImg from '../../vendor/images/menu-burger-horizontal-svgrepo-com.svg';
import PersonalInfoHeader from '../PersonalInfoHeader/PersonalInfoHeader';
import useScroll from '../../hooks/useScroll';

const Header: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, user, isMaint, correctLocal, mobMenuOpened } =
    useSelector((state) => ({
      isLogged: state.auth.isLogged,
      user: state.auth.user,
      isMaint: state.site.maintanence,
      correctLocal: state.site.currentLocal,
      mobMenuOpened: state.site.mobMenuOpened,
    }));
  const local = useLocal();

  const handleLogoutClick = () => {
    // dispatch(logoutThunk()); // обязательно с модальным окном сделать
  };

  const toggleMobileMenu = (e: any) => {
    // dispatch(toggleMobMenu({ opened: !mobMenuOpened }));
  };

  /* <Button name="only_outline" onClick={handleEnter}>
                  Temp Auto LogIn
                </Button>
                */

  return (
    <div className={style.container}>
      <div className={clsx(style.innerBox)}>
        <Link className={style.logolink} to='/'>
          <img alt='langsBig' className={style.logoImage} src={logoImg} />
        </Link>
        <div className={style.box}>
          <Menu />
          <div className={style.header__moreinfo}>
            {/* <LangsSwitcher /> later when engl version */}
            <PersonalInfoHeader />
          </div>
        </div>
        <div className={style.box_mobile}>
          <button
            className={clsx(
              style.mobileMenuButton,
              mobMenuOpened
                ? style.mobileMenuButton_hidden
                : style.mobileMenuButton_shown
            )}
            onClick={toggleMobileMenu}
            type='button'
          >
            <img
              alt='mobile Menu'
              className={style.burgerImg}
              src={mobileBurgerImg}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
