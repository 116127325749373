import React, { FC } from 'react';
import style from './ImagePart.module.scss';
import secondbanner from '../../../../../vendor/images/secondbanner.svg';

const ImagePart: FC = () => {
  const handleClick = () => {
    console.log('click');
  };

  return (
    <div className={style.imagePart}>
      <div className={style.innerBox}>        
        {/*
        <img
          alt="главная страница иностр языки"
          className={style.image}
          src="https://images.unsplash.com/photo-1527176930608-09cb256ab504?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGJvb2t8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
        />
        */}
          <img
          alt="главная страница иностр языки"
          className={style.image}
          src={secondbanner} />
          <div className={style.textPart}>
        <h1 className={style.title}>Легкое запоминание</h1>
        <p className={style.text}>Благодаря повторению быстро запомнятся слова и конструкции.</p>
        </div>
      </div>
    </div>
  );
};

export default ImagePart;
