type Tlist = {
  [key: string]: string;
};
type Tpositions = {
  home: Tlist;
  main: Tlist;
  dashboard: Tlist;
  learn: Tlist;
  blog: Tlist;
  header: Tlist;
};

type Ttranslations = {
  en: Tpositions;
  ru: Tpositions;
  de: Tpositions;
};

const translations: Ttranslations = {
  en: {
    home: {
      bannertitle: 'Learn Languages',
      bannersubTitle: 'more text about company',
      bannertext: 'Start Your Study',
      howWeWorkBtn: 'Start Your Study',
      howWeWorkTitle: 'How we work',
      blogTitle: 'Latest articles',

    },
    main: {
      noArticlesMessage: "There're no articles...",
      readMore: 'Read more...',
      tags: 'Tags: ',
      edit: ' Edit Article',
      delete: 'Delete Article',
      filterreset: 'Erase filter',
      noTags: "There're no tags for these articles",
    },
    dashboard: {
      title: 'your dashboard',
      startBtn: 'start',
      startStudyTxt: 'Start this lesson: ',
      title1start: 'Start your study',
      changeInterLang: '3. Change interface language:',
    },
    learn: {
      mainPageText: 'Homepage',
      newNoteText: 'New entry',
      loginText: 'Login',
      registerText: 'Register',
    },
    blog: {
      sidebarFirstTitle: 'To your Dashboard',
      sidebarBtnName: 'Move to Dashboard',
    },
    header: {
      homepage: 'Main',
      blog: 'Blog',
      dashboard: 'Dashboard',
      adminCMS: 'Admin page',
      signUpText: 'Sign Up',
      signInText: 'Sign In',
      about: 'about us',
      loggedas: 'You logged as:',
    },
  },
  ru: {
    home: {
      bannertitle: 'Учите языки',
      bannersubTitle: 'На нашем сайте вы найдете тренажер для изучения иностранных языков. Доведите Ваш навык до автоматизма!',
      bannertext: 'Вперед!',
      howWeWorkTitle: 'Как это работает',
      howWeWorkSubTitle: `Вам нужно войти в систему (сначала зарегистрироваться). Затем вы в своем кабинете
      можете начать обучение на тренажере. Вам предлагается фраза на вашем родном языке, вы должны будете ее сами перевести на
      иностранный язык. В помощь вам дается грамматическая справка, новые слова и возможность прослушать фразу.`,
      howWeWorkBtn: 'Перейти к обучению',
      ben1: 'Поэтапное усложнение',
      text1: 'позволяет Вам закреплять текущий материал',
      ben2: 'Много сопутствующей информации',
      text2: 'Мы предоставляем и грамматику, и аудио',
      ben3: 'Удобный тренажер',
      text3: ' - это учебник в новом формате.',
      ben4: 'Разные иностранные языки',
      text4: 'Вы можете выбрать именно тот язык, что изучаете',
      thirdTitle: 'Простая эффективная методика',
      thirdSubtitle: 'С помощью нашего тренажера вы доводите навык знания языка до автоматизма',
      thirdBtn: 'Перейти к учебе',
      blogTitle: 'Свежие статьи',

    },
    main: {
      noArticlesMessage: 'Нет статей...',
      readMore: 'Читать продолжение...',
      tags: 'Теги: ',
      edit: 'Редактировать статью',
      delete: 'Удалить статью',
      filterreset: 'Сбросить фильтр',
      noTags: 'Пока у этих статей нет тегов',
    },
    dashboard: {
      title: 'Ваш кабинет',
      startBtn: 'Начать',
      startStudyTxt: 'Приступить к обучению темы:',
      title1start: 'Начать обучение',
      changeInterLang: '3. Сменить язык интерфейса:',
    },
    learn: {
      mainPageText: 'Главная',
      newNoteText: 'Новая запись',
      loginText: 'Войти',
      registerText: 'Регистрация',
    },
    blog: {
      sidebarFirstTitle: 'Перейти в кабинет',
      sidebarBtnName: 'Перейти',
    },

    header: {
      homepage: 'Главная',
      blog: 'Блог',
      dashboard: 'Ваш кабинет',
      adminCMS: 'Для админов',
      signUpText: 'регистрация',
      signInText: 'вход',
      about: 'О нас',
      loggedas: 'Ваш логин:',
    },
  },
  de: {
    home: {
      bannertitle: 'Lerne Sprachen',
    },
    main: {
    },
    dashboard: {
    },
    learn: {
    },
    blog: {

    },
    header: {

    },
  },
};

export default translations;
