export const apiConfig = {
  url: 'https://api.ynstudios.ru',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const siteURL = 'http://localhost:3000'; //temp

export const tempArticles = [
  {urlslug: '/new', title: 'New method'}, {urlslug: '/new2', title: 'new met 2'}
];


export const allCoursesTemp = [
  {
    name: 'beginner',
    title: 'Начальный курс',
  },
  {
    name: 'intermediary',
    title: 'Средний курс',
  },
  {
    name: 'advanced',
    title: 'Продвинутый курс',
  },
  {
    name: 'traveller',
    title: 'Разговорник путешественника',
  },
  {
    name: 'pronounceCourse',
    title: 'Курс произношения',
  },
  {
    name: 'business',
    title: 'Бизнес курс',
  },
];


export const alldashTabs = [
  {title: 'Главное',
    path: '/dashboard',
  },
  {title: 'tab2',
    path: '/tab2',
  },
  {title: 'tab3',
    path: '/tab3',
  },
]