import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import style from './NotFoundPage.module.scss';

const NotFoundPage: FC = () => (
  <div className={style.container}>
    <Link className={style.returnLink} to='/'>&#8592; Главная</Link>
    <Link className={style.returnLink} to='/dashboard'>&#8592; Вернуться на доску</Link>
    <p>Страница не найдена, вернитесь на главную или на свою доску</p>
  </div>
);

export default NotFoundPage;
