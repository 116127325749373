import React, { FC, useState } from 'react';
import clsx from 'clsx';
import style from './Course.module.scss';
import { useDispatch, useSelector } from '../../..';
import Button from '../../../utils/ui-library/Buttons/Button/Button';

const Course: FC<{ course: any }> = ({ course }) => {
  /*const { langOpenCourses } = useSelector((state: any) => ({
      langOpenCourses: state.auth.user.langOpenCourses,
    }));
    const dispatch = useDispatch();
    console.log('cours id: ', course.courseId);
  */
  const addCourseNow = (e: any) => {
    e.stopPropagation();
  };
  /*
      if (
        !langOpenCourses.find((item: any) => item.courseId === course.courseId)
      ) {
        dispatch(addNewCourseThunk({ course }));
      } else {
        dispatch(removeNewCourseThunk({ course }));
      }
    };
    */

  return (
    <li key={course.courseName} className={clsx(style.course)}>
      <Button
        className={clsx(
          style.courseLink
          /*langOpenCourses.find((item: any) => item.courseId === course.courseId)
              ? style.courseLink__onroll
              : ''*/
        )}
        onClick={addCourseNow}
        name={course.courseId}
      >
        {course.courseName}{' '}
      </Button>
    </li>
  );
};
export default Course;
