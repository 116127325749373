import React, { FC } from 'react';
import style from './Banner.module.scss';
import Button from '../../../../../utils/ui-library/Buttons/Button/Button';
import useLocal from '../../../../../hooks/useLocal';
import NavButton from '../../../../../utils/ui-library/Buttons/NavButton/NavButton';
import bannerImg from '../../../../../vendor/images/learning-2.jpg';
import bannerImgIll from '../../../../../vendor/images/bannerpic1.svg';

const Banner: FC = () => {
  const handleClick = () => {
    console.log('click');
  };
  const local = useLocal();

  return (
    <div className={style.banner}>
      <div className={style.innerBox}>
        <div className={style.twoBlocks}>
          <div className={style.mainInfo}>
            <h1 className={style.title}>{local({ page: 'home', key: 'bannertitle' })}</h1>
            <p className={style.subtitle}>{local({ page: 'home', key: 'bannersubTitle' })}</p>
            <div className={style.buttonsBlock}>
              <Button
                className={style.button}
                onClick={handleClick}
                type="filled_highlight"
              >
                {local({ page: 'home', key: 'bannertext' })}
              </Button>
              <NavButton className={style.secondBut} name="simple_colored" path="/dashboard">Узнать больше</NavButton>
            </div>
          </div>
          {' '}
          <div className={style.imageInfo}>
            {/*<img alt="by Aaron Burden on Unsplash" src={bannerImg} className={style.image} />*/}
            <img alt="banner pic" src={bannerImgIll} className={style.image} /></div>
          {' '}

        </div>
      </div>
    </div>
  );
};

export default Banner;
