import React, { FC, useEffect } from 'react';
// import Method from '../../components/Method/Method';
import { useLocation } from 'react-router-dom';
import style from './MethodsPage.module.scss';
import Method from '../../components/Method/Method';
import Preloader from '../../components/Preloader/Preloader';
import useLocal from '../../hooks/useLocal';
import { useDispatch, useSelector } from '../..';
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';

const MethodsPage: FC<{ articles: any }> = ({ articles }) => {
  const local = useLocal();
  const dispatch = useDispatch();
  const location = useLocation();
  const { articlesLoaded } = useSelector((state) => ({
    articlesLoaded: state.site.articlesLoaded,
  }));

  /*
  useEffect(() => {
    const localName = location.pathname.slice(1, 3);
    dispatch(changeLocal({ lang: localName }));
  }, [location]); */

  const loadingMenthods = articlesLoaded ? <p>Articles are not added yet</p> : <Preloader />;

  return (
    <PageWithSidebar titletext={local({ page: 'home', key: 'blogTitle' })}>
      {articles.length > 0 ? (
        <div className={style.list}>
          {articles.map((item: any) => (
            <Method key={item.image} classColor={style.methodcolor} method={item} />
          ))}
        </div>
      ) : loadingMenthods}
    </PageWithSidebar>
  );
};

export default MethodsPage;
