import React, { FC, useEffect } from 'react';
import Userfront from "@userfront/core";
import { useDispatch, useSelector } from '../../..';
import useFormAndValidation from '../../../hooks/useForm';
import style from './Reset.module.scss';
import { PasswordResetForm } from '@userfront/react';

const Reset: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, user } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    user: state.auth.user,
  }));
  Userfront.init("5nx97dyb");

  const initial = {
    values: {
      email: '',
      password: '',
      passwordVerify: '',
    },
    errors: {
      email: '',
      password: '',
      passwordVerify: '',
    },
    validities: {
      email: true,
      password: true,
      passwordVerify: true,
    },
  };

  const {
    values, handleChange, errors, validities, isValid,
  } = useFormAndValidation(initial);

  const submitRegister = (e: any) => {
    e.preventDefault();
    // dispatch(loginFunctionThunk({ email: values.email, password: values.password }));
  };

  /*

  useEffect(() => {
    console.log('is log?', isLogged);
    if (isLogged) {
      dispatch(addLangsToUserThunk({ lang: 'esp' }));
    }
  }, [isLogged]);
  */

  const handleSubmit = (event:any) => {
    event.preventDefault();
    // Verify that the passwords match
    if (values.password !== values.passwordVerify) {
      return;
    }
    // Call Userfront.resetPassword()
    Userfront.resetPassword({
      password: values.password,
    });
  }

  return (
    <div className={style.container}>
       <div>
       <PasswordResetForm theme={{"colors":{"light":"#ffffff","dark":"#5e72e4","accent":"#13a0ff","lightBackground":"#fdfdfd","darkBackground":"#2d2d2d"},"colorScheme":"light","fontFamily":"Avenir, Helvetica, Arial, sans-serif","size":"default","extras":{"hideSecuredMessage":false}}} />;
      </div>
    </div>
  );
};

export default Reset;
