import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

type TPropsButton = {
  onClick: (
    ev: React.SyntheticEvent | React.FormEvent<HTMLFormElement>
  ) => void;
  type?: string;
  isSubmit?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  value?: string;
  name?:string;
};

const Button: FC<TPropsButton> = ({
  onClick,
  type = 'filled_big',
  isSubmit = false,
  disabled = false,
  className = '',
  children = 'Кнопка',
  value,
  name,
}) => {
  const types: { [key: string]: string } = {
    filled_small: styles.filled_small,
    filled_big: styles.filled_big,
    outline_small: styles.outline_small,
    only_outline: styles.only_outline,
    card_answer: styles.card_answer,
    card_nav_filled: styles.card_nav_filled,
    card_nav_outline: styles.card_nav_outline,
    filled_highlight: styles.filled_highlight,
    simple_text: styles.simple_text,
    dash_load_more: styles.dash_load_more,
  };

  return (
    <button
      className={clsx(styles.button, types[type] ? types[type] : '', className)}
      disabled={disabled}
      name={name}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      value={value}
    >
      {children}
    </button>
  );
};

export default Button;
