import React, { FC } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from './NavButton.module.scss';

type TPropsNavButton = {
  path: string;
  onClick?: (
    ev: React.SyntheticEvent | React.FormEvent<HTMLFormElement>
  ) => void;
  name?: string;
  activeClassName?: string;
  className?: string;
  children?: React.ReactNode;
};

const NavButton: FC<TPropsNavButton> = ({
  path,
  onClick,
  name = 'filled_big',
  className = '',
  activeClassName = '',
  children = 'Кнопка',
}) => {
  const names: { [key: string]: string } = {
    filled_small: styles.filled_small,
    filled_big: styles.filled_big,
    only_outline: styles.only_outline,
    filled_highlight: styles.filled_highlight,
    outline_highlight: styles.outline_highlight,
    accent_colored: styles.accent_colored,
    simple_colored: styles.simple_colored,
    header_menu_item: styles.header_menu_item,
    header_menu_item_highl: styles.header_menu_item_highl,
    header_menu_item_admin: styles.header_menu_item_admin,
    header_menu_item_mobile: styles.header_menu_item_mobile,
    langbutton: styles.langbutton,
    morebutton: styles.morebutton,
    admin_section: styles.admin_section,
    dashboard__filled: styles.dashboard__filled,
    dashboard__outline: styles.dashboard__outline,
    dashboard__simple: styles.dashboard__simple,
    dashtab: styles.dashtab,
  };

  return (
    <NavLink className={clsx(styles.button, names[name] ? names[name] : '', className)}
      onClick={onClick}
      to={path}
    >
      {children}
    </NavLink>
  );
};

export default NavButton;
