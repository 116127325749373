import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import style from './SingleMethodPage.module.scss';
import { useDispatch, useSelector } from '../..';
import Preloader from '../../components/Preloader/Preloader';

const SingleMethodPage: FC = () => {
  const params = useParams<{ slug: string }>();
  const dispatch = useDispatch();
  const [article, setArticle] = useState<any>({});

  const { articles, currentArticle } = useSelector((state) => ({
    articles: state.site.articles,
    currentArticle: state.site.currentArticle,
  }));

  console.log('params: ', params);

  // find the required article
  useEffect(() => {
    if ('slug' in params && Object.keys(currentArticle).length === 0) {
      // dispatch(getArticleBySlugThunk(params.slug)); retrieve artticel from base, as its on wordpress
    }
  }, [params, articles, currentArticle]);

  return (
    <div className={style.box}>
      {Object.keys(currentArticle).length !== 0 ? (
        <>
          <h1 className={style.title}>{currentArticle.titleRu}</h1>
          {currentArticle.image.length > 0 ? (
            <img
              alt={currentArticle.title}
              className={style.image}
              src={currentArticle.image}
            />
          ) : (
            ''
          )}
          <div className={style.textBox}>
            <div
              dangerouslySetInnerHTML={{ __html: currentArticle.mainTextRu }}
              className={style.text}
            />
          </div>
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default SingleMethodPage;
