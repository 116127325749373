import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './HowWeWork.module.scss';
import useLocal from '../../../../../hooks/useLocal';
import NavButton from '../../../../../utils/ui-library/Buttons/NavButton/NavButton';
import firstImg from '../../../../../vendor/images/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg';
import secondImg from '../../../../../vendor/images/carlos-muza-hpjSkU2UYSU-unsplash.jpg';
import thirdImg from '../../../../../vendor/images/glenn-carstens-peters-npxXWgQ33ZQ-unsplash.jpg';
import fourthImg from '../../../../../vendor/images/sigmund-ZEZLu8xPpv4-unsplash.jpg';


const Benefit: FC<{ item: any }> = ({ item }) => (
  <li className={style.benefit}>
    <img alt={item.imgAlt} className={style.benefitImage} src={item.image} />
    <p className={style.benefitTitle}>{item.title}</p>
    <p className={style.subtitle}>{item.text}</p>
  </li>
);

const HowWeWork: FC = () => {
  const local = useLocal();
  const benefits = [
    {
      image: firstImg, imgAlt: 'by Nicholas Cappello on Unsplash', title: local({ page: 'home', key: 'ben1' }), text: local({ page: 'home', key: 'text1' }), id: 1,
    },
    {
      image: secondImg, imgAlt: 'by Carlos Muza on Unsplash', title: local({ page: 'home', key: 'ben2' }), text: local({ page: 'home', key: 'text2' }), id: 2,
    },
    {
      image: thirdImg, imgAlt: 'by Glenn Carstens-Peters on Unsplash', title: local({ page: 'home', key: 'ben3' }), text: local({ page: 'home', key: 'text3' }), id: 3,
    },
    {
      image: fourthImg, imgAlt: 'by Sigmund on Unsplash', title: local({ page: 'home', key: 'ben4' }), text: local({ page: 'home', key: 'text4' }), id: 4,
    },
  ];
  /*  {' '}
            &#8594;
            */

  return (
    <div className={style.howWeWork}>
      <div className={style.innerBox}>
        <div className={style.twoBlocks}>
          <div className={style.mainInfo}>
            <div className={style.textBlock}>
              <h2 className={style.title}>{local({ page: 'home', key: 'howWeWorkTitle' })}</h2>
              <p className={style.subtitle}>{local({ page: 'home', key: 'howWeWorkSubTitle' })}</p>
              <NavButton className={style.link} name='morebutton' path='/'>
                {local({ page: 'home', key: 'howWeWorkBtn' })}
              </NavButton>
            </div>

          </div>
          <div className={style.imageInfo} />
        </div>

        <div className={style.benefits}>
          <ul className={style.benefitsList}>
            {benefits.map((benefit) => (
              <Benefit key={benefit.id} item={benefit} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
