import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import style from './Dashboard.module.scss';
import { useDispatch, useSelector } from '../..';
import ShowingTab from './ShowingTab/ShowingTab';
import MainTabs from './mainTabs/MainTabs';
import DashTabs from './DashTabs/DashTabs';
import { useLocation } from 'react-router-dom';
import { alldashTabs } from '../../utils/constants';

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const { dashCourseTab, isLogged } = useSelector((state) => ({
    dashCourseTab: state.site.dashCourseTab,
    isLogged: state.auth.isLogged,
  }));

  // yet using temp courses

  return (
    <div className={style.box}>
      <div className={style.container}>
        <DashTabs />
        <div className={style.box}>
          {pathname === alldashTabs[0].path ? (
            <div className={style.grid}>
              <div className={style.start}>start</div>
              <div className={style.progress}>progress</div>
              <div className={style.starred}>starred</div>
            </div>
          ) : (
            ''
          )}
          {pathname === alldashTabs[1].path ? (
            <div className={style.grid}>
              <div className={style.pronounce}>pronounce</div>
              <div className={style.tips}>tips</div>
              <div className={style.warmout}>warmout</div>
              <div className={style.upload}>upload</div>
            </div>
          ) : (
            ''
          )}
          {pathname === alldashTabs[2].path ? <div>3rd tab</div> : ''}
          <div className={style.dash}>
            <MainTabs />
            <ShowingTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
