import React, { FC, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { siteURL } from '../../../utils/constants';
import MainPage from '../MainPage/MainPage';

const HomePageRu: FC = () => (
  <HelmetProvider>
    <Helmet>
      <title>Учим языки</title>
      <meta content='Учим языки на русском языке' name='description' />
      <link href={`${siteURL}/ru`} hrefLang='ru' rel="alternate" />
    </Helmet>
    <MainPage local="ru" />
  </HelmetProvider>
);

export default HomePageRu;
