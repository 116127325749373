import { useContext } from 'react';
import { useSelector } from '..';
import { TranslationContext } from '../contexts/context';

const useLocal = () => {
  const context = useContext(TranslationContext);
  const currentLanguage = useSelector((store: any) => store.site.currentLocal);

  return (key: { key: string; page: string }) => {
    let translation = key.key;
    if (
      context[currentLanguage][key.page] &&
      context[currentLanguage][key.page][key.key]
    ) {
      translation = context[currentLanguage][key.page][key.key];
    }

    const result = translation || key.key;

    return result;
  };
};

export default useLocal;
