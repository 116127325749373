import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import style from './Footer.module.scss';
import Menu from '../Menu/Menu';
import { useDispatch, useSelector } from '../..';
import Button from '../../utils/ui-library/Buttons/Button/Button';
import { tempAutologin } from '../../services/reducers/authReducer';

const Footer: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, user } = useSelector((state: any) => ({
    isLogged: state.auth.isLogged,
    user: state.auth.user,
  }));

  const handleLogoutClick = () => {
    // dispatch(logoutThunk()); // обязательно с модальным окном сделать
  };

  const handleTempEnter = (e: any) => {
    e.preventDefault();
    const transferData = { email: 'newtest2@ya.ru', password: '1122' };
    dispatch(tempAutologin({ user: transferData }));
    // dispatch(loginFunctionThunk(transferData));
    // TODO: сделать форму для логина
  };

  return (
    <div className={style.container}>
      <div className={clsx(style.innerBox, style.footer)}>
        <div className={style.footerColumn}>
          <p className={style.subtitle}>&#169; Langsbig, 2022</p>
        </div>
        <div className={style.footerColumn}>
          <p className={style.subtitle}>Навигация</p>
          <Menu classN={style.menu} />
        </div>
        <div className={style.footerColumn}>
          <div className={style.columnBlock}>
            <p className={style.subtitle}>Авторизация</p>
            <div className={style.linksList}>
              {isLogged ? (
                <>
                  <p>
                    Logged in as:
                    {/*<span>{user.email}</span> why dont we want him */}
                  </p>
                  <button onClick={handleLogoutClick} type='button'>
                    Sign out
                  </button>
                </>
              ) : (
                <>
                  <Link className={style.link} to='/signup'>
                    Sign Up
                  </Link>
                  <Link className={style.link} to='/login'>
                    Log In
                  </Link>
                  <Button
                    className={style.button}
                    onClick={handleTempEnter}
                    type='filled_highlight'
                  >
                    Temp Auto LogIn
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={style.columnBlock}>
            <p className={style.subtitle}>Контакты</p>
          </div>
        </div>
      </div>
      <div className={style.decoration} />
    </div>
  );
};

export default Footer;
