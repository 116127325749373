import React, { FC, useState } from 'react';
import clsx from 'clsx';
import style from './MainTabs.module.scss';
import { changeCourseTab } from '../../../services/reducers/siteReducer';
import { useDispatch, useSelector } from '../../..';


const MainTabs: FC = () => {
    const dispatch = useDispatch(); 
    const switchTab = (e: any) => {
        const tabName = e.target.name;
        dispatch(changeCourseTab(tabName));
      };
      const { dashCourseTab } = useSelector((state) => ({
        dashCourseTab: state.site.dashCourseTab,
      }));

  return (
    <div className={style.dash__tabs}>
      <button
        className={clsx(
          style.dash__tab,
          style.dash__tabFirst,
          dashCourseTab === 'lessons' ? style.dash__tab_active : ''
        )}
        name='lessons'
        onClick={switchTab}
        type='button'
      >
        Текущие курсы и уроки
      </button>
      <button
        className={clsx(
          style.dash__tab,
          style.dash__tabSecond,
          dashCourseTab === 'courses' ? style.dash__tab_active : ''
        )}
        name='courses'
        onClick={switchTab}
        type='button'
      >
        Все курсы
      </button>
    </div>
  );

}

export default MainTabs;