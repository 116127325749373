import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import style from './MainPage.module.scss';
import Banner from './parts/Banner/Banner';
import Benefits from './parts/Benefits/Benefits';
import FirstPreview from './parts/FirstPreview/FirstPreview';
import ImagePart from './parts/ImagePart/ImagePart';
import HowWeWork from './parts/HowWeWork/HowWeWork';
import Additional from './parts/Additional/Additional';
import { useDispatch } from '../../..';

const MainPage: FC<{ local: string }> = ({ local }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  /*
  useEffect(() => {
    dispatch(changeLocal({ lang: local }));
  }, [location]);
  */
  return (

    <div className={style.container}>
      <Banner />
      <HowWeWork />
      <FirstPreview />
      {/* <Benefits /> */}
      <ImagePart />
      <Additional />
    </div>

  );
};
export default MainPage;
