import React, { FC } from 'react';
import { Route, RouteProps, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from '../..';
import Preloader from '../Preloader/Preloader';

const ProtRouteAuth: FC<RouteProps> = ({ children, ...rest }) => {
  const { path } = { ...rest };
  const { isLogged, isTokenPending } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    isTokenPending: state.auth.isTokenRequesting,
  }));
/*
  if (isTokenPending) {
    return (
      <Route element={<Preloader />} {...rest} />
    );
  }*/

  return !isLogged ? <Outlet/> : <Navigate to='/dashboard'/>
};

export default ProtRouteAuth;
