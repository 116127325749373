import React, { FC } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../..';
import style from './PersonalInfoHeader.module.scss';
import useLocal from '../../hooks/useLocal';
import NavButton from '../../utils/ui-library/Buttons/NavButton/NavButton';
import Button from '../../utils/ui-library/Buttons/Button/Button';

const PersonalInfoHeader: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { isLogged, user, isMaint, correctLocal, mobMenuOpened } = useSelector(
    (state) => ({
      isLogged: state.auth.isLogged,
      user: state.auth.user,
      isMaint: state.site.maintanence,
      correctLocal: state.site.currentLocal,
      mobMenuOpened: state.site.mobMenuOpened,
    })
  );
  const local = useLocal();

  const handleLogoutClick = () => {
    // dispatch(logoutThunk()); // обязательно с модальным окном сделать
    // dispatch(toggleMobMenu({ opened: false }));
  };

  const toggleMobileMenu = (e: any) => {
    // dispatch(toggleMobMenu({ opened: !mobMenuOpened }));
  };

  /*
   <span className={style.header__span}>
                  MM:
                  <span className={clsx(style.header__span, style.header__bold)}>
                    {isMaint ? 'ON' : 'OFF'}
                  </span>
                </span>
                */
  const ifLoggedShowDash = (
    <div className={style.loggedInInfo}>
      <div className={style.loggedEmailInfo}>
        <p className={style.header__par}>
          {local({ page: 'header', key: 'loggedas' })}{' '}
          {/*(isLogged && user) ? user.email : ''*/}
        </p>
        <p className={style.header__par}>
          {/*<span className={style.header__span}>{isAdmin ? 'Admin' : ''}</span>*/}
        </p>
      </div>
      <div className={style.navButtonsLogged}>
        {isMobile ? (
          <NavButton
            className={style.buttonPersThird}
            name='accent_colored'
            onClick={toggleMobileMenu}
            path='/dashboard'
          >
            {local({ page: 'header', key: 'dashboard' })}
          </NavButton>
        ) : (
          <NavButton
            className={style.buttonPersThird}
            name='accent_colored'
            path='/dashboard'
          >
            {local({ page: 'header', key: 'dashboard' })}
          </NavButton>
        )}
        <Button onClick={handleLogoutClick} type='simple_text'>
          Выйти {/* needs to be localized */}
        </Button>
      </div>
    </div>
  );

  const mobileLinks = (
    <>
      <NavButton
        className={style.buttonPersFirst}
        name='accent_colored'
        onClick={toggleMobileMenu}
        path='/signup'
      >
        {local({ page: 'header', key: 'signUpText' })}
      </NavButton>
      <NavButton
        className={style.buttonPersSecond}
        name='simple_colored'
        onClick={toggleMobileMenu}
        path='/signin'
      >
        {local({ page: 'header', key: 'signInText' })}
      </NavButton>
    </>
  );

  const generalLinks = (
    <>
      <NavButton
        className={style.buttonPersFirst}
        name='accent_colored'
        path='/signup'
      >
        {local({ page: 'header', key: 'signUpText' })}
      </NavButton>
      <NavButton
        className={style.buttonPersSecond}
        name='simple_colored'
        path='/signin'
      >
        {local({ page: 'header', key: 'signInText' })}
      </NavButton>
      {/* make just Button here which opens Modal */}
    </>
  );

  const personalLinksIfMobile = isMobile ? mobileLinks : generalLinks;

  return (
    <div
      className={clsx(
        style.personalinfo,
        isLogged ? style.personalinfo_logged : style.personalinfo_notLogged
      )}
    >
      {isLogged ? ifLoggedShowDash : personalLinksIfMobile}
    </div>
  );
};

export default PersonalInfoHeader;
