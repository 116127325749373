import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './PageWithSidebar.module.scss';
import useLocal from '../../hooks/useLocal';

const PageWithSidebar: FC<{
  children: React.ReactNode;
  titletext?: string;
}> = ({ children, titletext }) => {
  const local = useLocal();

  return (
    <div className={style.box}>
      <div className={style.withSidebar}>
        <div>
          <h1 className={style.title}>{titletext}</h1>
          {children}
        </div>
        <div className={style.sidebar}>
          <div className={style.widget}>
            <h2 className={style.title}>
              {local({ page: 'blog', key: 'sidebarFirstTitle' })}
            </h2>
            <Link className={style.link} to='/dashboard'>
              {local({ page: 'blog', key: 'sidebarBtnName' })}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWithSidebar;
