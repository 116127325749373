import { apiConfig } from './constants';

const _getResponse = (data: any) => data.json();

export const checkTokenApi = () => fetch(`${apiConfig.url}/token`, {
  method: 'GET',
  credentials: 'include', // ?
  headers: apiConfig.headers,
}).then(_getResponse);

export const registerApi = (gotData: any) => {
  console.log('in register api file', gotData);
  return fetch(`${apiConfig.url}/register`, {
    method: 'POST',
    headers: apiConfig.headers,
    body: JSON.stringify(gotData),
  }).then(_getResponse);
};


export const loginApi = (gotData: any) => fetch(`${apiConfig.url}/login`, {
  method: 'POST',
  credentials: 'include',
  headers: apiConfig.headers,
  body: JSON.stringify({ email: gotData.email, password: gotData.password }),
}).then(_getResponse);